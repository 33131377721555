import resource from '../../resource';

const state = {
  businesses: null,
};

const getters = {
  getBusinesses(state) {
    return state.businesses;
  },
};

const mutations = {
  SET_BUSINESSES(state, data) {
    state.businesses = data;
  },
};

const actions = {
  fetchBusinesses(context, { waiter }) {
    return resource.get(`/waiters/${waiter}/businesses`)
      .then((response) => {
        context.commit('SET_BUSINESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchBusiness(context, { waiter, business }) {
    return resource.get(`/waiters/${waiter}/businesses/${business}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchBusinesses(context, { waiter, search }) {
    return resource.get(`/waiters/${waiter}/businesses`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_BUSINESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toBusinessesPage(context, { waiter, search, page }) {
    return resource.get(`/waiters/${waiter}/businesses`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_BUSINESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createBusiness(context, { waiter, payload }) {
    return resource.post(`/waiters/${waiter}/businesses`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateBusiness(context, { waiter, business, payload }) {
    return resource.put(`/waiters/${waiter}/businesses/${business}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteBusiness(context, { waiter, business }) {
    return resource.delete(`/waiters/${waiter}/businesses/${business}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
