import resource from "../../resource";
const tokenKeyName = 'PUB_WAITER_TOKEN';
const waiterKeyName = 'PUB_WAITER_WAITER';

const state = {
  token: null,
  waiter: null
};

const getters = {
  getLoginToken(state) {
    if (localStorage.getItem(tokenKeyName)){
      state.token = localStorage.getItem(tokenKeyName);
    }
    return state.token;
  },
  getLoginAuthWaiter(state) {
    if (localStorage.getItem(waiterKeyName)){
      state.waiter = JSON.parse(localStorage.getItem(waiterKeyName));
    }
    return state.waiter;
  },
  getLoginAuthCheck(state) {
    return !!state.token || !!localStorage.getItem(tokenKeyName);
  },
};

const mutations = {
  SET_LOGIN_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(tokenKeyName, token);
  },
  REMOVE_LOGIN_TOKEN(state) {
    state.token = null;
    localStorage.removeItem(tokenKeyName);
  },
  SET_LOGIN_WAITER(state, waiter) {
    state.waiter = waiter;
    localStorage.setItem(waiterKeyName, JSON.stringify(waiter));
  },
  REMOVE_LOGIN_WAITER(state) {
    state.waiter = null;
    localStorage.removeItem(waiterKeyName);
  },
};

const actions = {
  loginCreateSession(context, credentials) {
    return resource.post('/auth/waiter/login', credentials)
      .then((response) => {
        context.commit('SET_LOGIN_TOKEN', response.data.access_token);
        return context.dispatch('loginFetchAuthWaiter');
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveWaiter');
        return Promise.reject(error);
      });
  },
  loginRemoveSession(context) {
    return resource.post('/auth/waiter/logout')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveWaiter');
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveWaiter');
        return Promise.reject(error);
      });
  },
  loginRemoveSessions(context) {
    return resource.post('/auth/waiter/revoke')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveWaiter');
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  loginFetchAuthWaiter(context) {
    return resource.get('/auth/waiter/me')
      .then((response) => {
        context.dispatch('loginSetWaiter', response.data.data);
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveSession');
        return Promise.reject(error);
      });
  },
  loginSetToken(context, data) {
    context.commit('SET_LOGIN_TOKEN', data);
  },
  loginRemoveToken(context) {
    context.commit('REMOVE_LOGIN_TOKEN');
  },
  loginRemoveWaiter(context) {
    context.commit('REMOVE_LOGIN_WAITER');
  },
  loginSetWaiter(context, data) {
    context.commit('SET_LOGIN_WAITER', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
