const themeKeyName = 'PUB_WAITER_THEME_DARK';

const state = {
  themeDark: false
};

const getters = {
  getThemeDark(state) {
    if(localStorage.getItem(themeKeyName) && !!localStorage.getItem(themeKeyName)){
      state.themeDark = JSON.parse(localStorage.getItem(themeKeyName));
    }
    return state.themeDark;
  },
};

const mutations = {
  SET_THEME_DARK(state, status) {
    localStorage.setItem(themeKeyName, status);
    state.themeDark = status;
  },
};

const actions = {
  setThemeDark(context, data) {
    context.commit('SET_THEME_DARK', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
