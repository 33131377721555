<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Details-->
    <v-col cols="12" v-if="order">
      <v-card flat outlined>
        <v-card-subtitle class="text-uppercase py-2">
          Itens do pedido
        </v-card-subtitle>
        <v-card-text>
          <template v-for="orderItem in order.order_items">
            <span class="d-block" :key="`order-item-${orderItem.id}`">{{ orderItem.quantity }}x {{ orderItem.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderItem.total) }}</span>
            <template v-for="orderOption in orderItem.order_options">
              <span class="d-block pl-5" :key="`order-option-${orderOption.id}`">{{ orderOption.name }}</span>
              <template v-for="orderSelection in orderOption.order_selections">
                <span class="d-block pl-10" :key="`order-selection-${orderSelection.id}`">{{ orderSelection.quantity }}x {{ orderSelection.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderSelection.amount) }}</span>
              </template>
            </template>
            <span class="d-block py-2" :key="`comment-${orderItem.id}`" v-if="orderItem.comments">
              Obs. <span class="text-uppercase">{{ orderItem.comments }}</span>
            </span>
          </template>
        </v-card-text>

        <template v-if="order.service === 'DELIVERY'">
          <v-card-subtitle class="text-uppercase py-2">
            Taxa de entrega
          </v-card-subtitle>
          <v-card-text>
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.delivery_tax) }}
          </v-card-text>
        </template>

        <template v-if="order.coupon">
          <v-card-subtitle class="text-uppercase py-2">
            Cupom de desconto
          </v-card-subtitle>
          <v-card-text v-if="order.coupon.type === 'DECIMAL'">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.coupon.amount) }} sobre os itens
          </v-card-text>
          <v-card-text v-if="order.coupon.type === 'PERCENTAGE'">
            {{ order.coupon.percentage }}% sobre os itens
          </v-card-text>
          <v-card-text v-if="order.coupon.type === 'DELIVERY_FREE'">
            Frete grátis
          </v-card-text>
        </template>

        <v-card-subtitle class="text-uppercase py-2">
          Valor total do pedido
        </v-card-subtitle>
        <v-card-text>
          {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.total) }}
        </v-card-text>

        <v-card-subtitle class="text-uppercase py-2">
          Pagamento
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" v-for="payment in order.payments" :key="`payment-${payment.id}`">
              <span class="d-block" v-if="payment.type === 'IN_APP'">Pago pelo APP, não há cobrança na entrega.</span>
              <span class="d-block" v-else>Pagamento com <b>{{ paymentType(payment.type) }}</b> (<b>{{ payment.payment_method.name }}</b>) na entrega.</span>
              <span class="d-block" v-if="payment.type === 'CASH' && payment.cash_change">Requer troco para {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.cash_change) }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'a-order',
    data: () => ({
      order: null,
    }),
    created() {
      this.$axios.get(`/waiters/${this.waiter.id}/orders/${this.$route.params.order}`)
        .then((response) => {
          this.order = response.data.data;
        });
    },
    methods: {
      orderStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'PREPARING':
            return 'Preparando';
          case 'DELIVERING':
            return 'Saiu Para Entrega';
          case 'DELIVERED':
            return 'Entregue';
          case 'CANCELLED':
            return 'Cancelado';
          default:
            return 'Não definido';
        }
      },
      paymentStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Processando';
          case 'SUCCEEDED':
            return 'Aprovado';
          case 'FAILED':
            return 'Rejeitado';
          case 'CANCELLED':
            return 'Cancelado';
          case 'CASH_BACK':
            return 'Estornado';
          default:
            return 'Não definido';
        }
      },
      paymentType(type) {
        switch (type) {
          case 'IN_APP_PAYMENT':
            return 'No APP';
          case 'CREDIT_CARD':
            return 'Cartão de Crédito';
          case 'CURRENCY':
            return 'Dinheiro';
          case 'DEBIT_CARD':
            return 'Cartão de Debito';
          case 'MEAL_TICKET':
            return 'Vale Refeição';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      waiter() {
        return this.$store.getters.getLoginAuthWaiter;
      },
      firebaseMessaging() {
        return this.$store.getters.getFirebaseMessaging;
      },
    },
  };
</script>
