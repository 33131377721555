<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <a-profile-avatar-update/>
    </v-col>
    <v-col cols="12">
      <a-pairing-code/>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <v-card flat outlined class="fill-height" :to="{ name: 'profile_about' }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Informações Pessoais</v-list-item-title>
            <v-list-item-subtitle>Altere nome, visibilidade.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <v-card flat outlined class="fill-height" :to="{ name: 'profile_security' }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Dados de Segurança</v-list-item-title>
            <v-list-item-subtitle>Altere senhas de acesso e email.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-btn large block depressed @click="revoke">Sair de todas as aplicações</v-btn>
    </v-col>
    <v-col cols="12">
      <v-btn large block depressed @click="deleteAccount">Excluir conta de usuário</v-btn>
    </v-col>

    <v-col cols="12" class="pt-5">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Termos e Condições</h1>
    </v-col>
    <v-col cols="12">
      <span class="pt-3">Leia os
        <router-link :to="{ name: 'terms' }" target="_blank">Termos e Condições</router-link> e as
        <router-link :to="{ name: 'policies' }" target="_blank">Politicas de Privacidade</router-link>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import APairingCode from "@/components/Profile/APairingCode";
import AProfileAvatarUpdate from "@/components/Profile/AProfileAvatarUpdate";

export default {
  name: 'a-profile',
  components: {AProfileAvatarUpdate, APairingCode},
  methods: {
    async revoke() {
      if (confirm('Tem certeza que deseja sair de todos os aplicativos?')) {
        await this.$store.dispatch('loginRemoveSessions');
        await this.$store.dispatch('setDrawerStatus', null);
        await this.$router.push({name: 'login'});
      }
    },
    async deleteAccount() {
      if (confirm('Tem certeza que deseja excluir sua conta de cliente no PointUrbano?')) {
        await this.$axios.delete(`/waiters/${this.waiter.id}`);
        await this.$store.dispatch('loginRemoveToken');
        await this.$store.dispatch('loginRemoveWaiter');
        await this.$store.dispatch('setDrawerStatus', null);
        await this.$router.push({name: 'login'});
      }
    },
  },
  computed: {
    waiter() {
      return this.$store.getters.getLoginAuthWaiter;
    },
  },
};
</script>
