import resource from '../../resource';

const state = {
  telephones: null,
};

const getters = {
  getTelephones(state) {
    return state.telephones;
  },
};

const mutations = {
  SET_TELEPHONES(state, data) {
    state.telephones = data;
  },
};

const actions = {
  fetchTelephones(context, { waiter }) {
    return resource.get(`/waiters/${waiter}/telephones`)
      .then((response) => {
        context.commit('SET_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchTelephone(context, { waiter, telephone }) {
    return resource.get(`/waiters/${waiter}/telephones/${telephone}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchTelephones(context, { waiter, search }) {
    return resource.get(`/waiters/${waiter}/telephones`, { params: { telephone: search } })
      .then((response) => {
        context.commit('SET_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toTelephonesPage(context, { waiter, search, page }) {
    return resource.get(`/waiters/${waiter}/telephones`, { params: { telephone: search, page: page } })
      .then((response) => {
        context.commit('SET_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createTelephone(context, { waiter, payload }) {
    return resource.post(`/waiters/${waiter}/telephones`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateTelephone(context, { waiter, telephone, payload }) {
    return resource.put(`/waiters/${waiter}/telephones/${telephone}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteTelephone(context, { waiter, telephone }) {
    return resource.delete(`/waiters/${waiter}/telephones/${telephone}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
