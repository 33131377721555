<template>
  <v-row dense v-touch="{ down: () => pullToRefresh() }" align-content="start" class="fill-height">
    <!--Online-->
    <v-col cols="12">
      <a-profile-online-switch/>
    </v-col>
    <!--Orders-->
    <template v-if="orders">
      <v-col cols="12" md="6" v-for="order in orders.data" :key="order.id">
        <a-order-tile :order="order"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import AOrderTile from "@/components/AOrderTile";
import AProfileOnlineSwitch from "@/components/Profile/AProfileOnlineSwitch";

export default {
  name: 'a-dashboard',
  components: {AProfileOnlineSwitch, AOrderTile},
  data: () => ({
    orders: null,
    polling: null,
  }),
  created() {
    this.fetchOrders();
    this.pollData();
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    pollData () {
      this.polling = setInterval(() => {
        this.fetchOrders();
      }, 60000);
    },
    fetchOrders() {
      this.$axios.get(`/waiters/${this.waiter.id}/orders`, { params: { all: 1, status: ['PREPARING'] }, loader: false })
        .then((response) => {
          this.orders = response.data;
          if (response.data.data.length) {
            this.$store.dispatch('setShowOrderDialogStatus', false);
            this.$store.dispatch('setOrderDialogPayload', response.data.data[0]);
            this.$store.dispatch('setShowOrderDialogStatus', true);
            this.$audio.play();
          }
        });
    },
    pullToRefresh() {
      if(document.scrollingElement.scrollTop === 0) {
        this.fetchOrders();
      }
    },
  },
  computed: {
    waiter() {
      return this.$store.getters.getLoginAuthWaiter;
    },
    firebaseMessaging() {
      return this.$store.getters.getFirebaseMessaging;
    },
  },
};
</script>
