<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Estabelecimento</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn disabled icon @click="$router.push({ name: 'businesses_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="businesses">
      <v-col cols="12">
        <v-text-field v-model="businessesSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchBusinesses()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="businessesHeaders"
                    :items="businesses.data"
                    :page.sync="businesses.meta.current_page"
                    :items-per-page.sync="businesses.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.thumbnail="{ item }">
            <v-img width="48" height="48" :src="item.thumbnail ? item.thumbnail.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>
          </template>
          <template v-slot:item.online="{ item }">
            <v-simple-checkbox v-model="item.online" disabled/>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn disabled :to="{ name: 'businesses_update', params: { business: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyBusiness(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="businesses.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-business-list',
    data: () => ({
      page: 1,
      businessesSearch: '',
      businessesHeaders: [
        { text: 'Thumbnail', align: 'center', width: 48, value: 'thumbnail' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Online', value: 'online' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toBusinessesPage', {
          waiter: this.waiter.id,
          search: this.businessesSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchBusinesses();
    },
    methods: {
      fetchBusinesses() {
        this.$store.dispatch('fetchBusinesses', {
          waiter: this.waiter.id,
        });
      },
      searchBusinesses() {
        this.$store.dispatch('searchBusinesses', {
          waiter: this.waiter.id,
          search: this.businessesSearch
        });
      },
      destroyBusiness(business) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteBusiness', {
            waiter: this.waiter.id,
            business: business.id
          }).then(() => {
            this.fetchBusinesses();
          });
        }
      },
    },
    computed: {
      businesses() {
        return this.$store.getters.getBusinesses;
      },
      waiter() {
        return this.$store.getters.getLoginAuthWaiter;
      },
    },
  };
</script>
