<template>
  <v-card flat outlined class="fill-height">
    <template v-if="waiter && !waiter.business">
      <v-card-title>Código de Pareamento</v-card-title>
      <v-card-text>
        <span class="d-block caption">
          Utilize o código de pareamento sempre que um estabelecimento solicitar um vinculo com sua conta.
        </span>
        <v-text-field readonly outlined dense hide-details :value="waiter.pairing_code" prefix="#"/>
      </v-card-text>
    </template>
    <template v-else>
      <v-list-item two-line>
        <v-list-item-avatar size="50" rounded>
          <v-img :src="waiter.business.avatar ? waiter.business.avatar.url : require('../../assets/placeholder.png')"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ waiter.business.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ waiter.business.category.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-btn text @click="cancelPairing">Cancelar Pareamento</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'a-pairing-code',
  methods: {
    cancelPairing() {
      if (confirm('Tem certeza que deseja cancelar o pareamento?')) {
        this.$axios.put(`/waiters/${this.waiter.id}`, { business_id: null })
            .then((response) => {
              this.$store.dispatch('loginSetDriver', response.data.data);
            });
      }
    },
  },
  computed: {
    waiter() {
      return this.$store.getters.getLoginAuthDriver;
    },
  }
};
</script>
