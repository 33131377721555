<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12" sm="12" md="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Entregas</h1>
    </v-col>
    <!--Orders-->
    <template v-if="orders">
      <v-col cols="12">
        <v-data-iterator
            :items="orders.data"
            :page.sync="orders.meta.current_page"
            :items-per-page.sync="orders.meta.per_page"
            hide-default-footer
            disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="12" md="6" v-for="item in props.items" :key="item.id">
                <v-card flat outlined class="fill-height">
                  <v-list-item two-line :to="{ name: 'order', params: { order: item.id } }">
                    <v-list-item-avatar rounded size="80">
                      <v-img :src="item.business.thumbnail.url" aspect-ratio="1" v-if="item.business.thumbnail"/>
                      <v-img :src="require('../../assets/placeholder.png')" aspect-ratio="1" v-else/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        #{{ item.identification }} - <span :class="orderStatusColor(item.status)">{{ orderStatus(item.status) }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.business.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Previsão {{ DateTime.fromSQL(item.delivery_prevision).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Valor: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="orders.meta.last_page" :total-visible="7"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-orders',
    data: () => ({
      page: 1,
      orders: null,
      ordersSearch: '',
      DateTime: DateTime,
    }),
    watch: {
      page(value) {
        this.$axios.get(`/waiters/${this.waiter.id}/orders`, { params: { name: this.ordersSearch, page: value } })
          .then((response) => {
            this.orders = response.data;
          });
      },
    },
    created() {
      this.fetchOrders();
    },
    methods: {
      fetchOrders() {
        this.$axios.get(`/waiters/${this.waiter.id}/orders`)
          .then((response) => {
            this.orders = response.data;
          });
      },
      searchOrders() {
        this.$axios.get(`/waiters/${this.waiter.id}/orders`, { params: { name: this.ordersSearch } })
          .then((response) => {
            this.orders = response.data;
          });
      },
      orderStatusColor(status) {
        switch (status) {
          case 'PENDING':
            return 'amber--text darken-4';
          case 'PREPARING':
            return 'blue--text darken-4';
          case 'DELIVERING':
            return 'purple--text darken-4';
          case 'DELIVERED':
            return 'green--text darken-4';
          case 'CANCELLED':
            return 'red--text darken-4';
          default:
            return 'grey--text darken-4';
        }
      },
      orderStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'PREPARING':
            return 'Preparando';
          case 'DELIVERING':
            return 'Saiu Para Entrega';
          case 'DELIVERED':
            return 'Entregue';
          case 'CANCELLED':
            return 'Cancelado';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      waiter() {
        return this.$store.getters.getLoginAuthWaiter;
      },
    },
  };
</script>
