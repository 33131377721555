import resource from "../../resource";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
  resetPassword(context, credentials) {
    return resource.put('/auth/waiter/password', credentials)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
