import resource from '../../resource';

const state = {
  addresses: null,
};

const getters = {
  getAddresses(state) {
    return state.addresses;
  },
};

const mutations = {
  SET_ADDRESSES(state, data) {
    state.addresses = data;
  },
};

const actions = {
  fetchAddresses(context, { waiter }) {
    return resource.get(`/waiters/${waiter}/addresses`)
      .then((response) => {
        context.commit('SET_ADDRESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchAddress(context, { waiter, address }) {
    return resource.get(`/waiters/${waiter}/addresses/${address}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchAddresses(context, { waiter, search }) {
    return resource.get(`/waiters/${waiter}/addresses`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_ADDRESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toAddressesPage(context, { waiter, search, page }) {
    return resource.get(`/waiters/${waiter}/addresses`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_ADDRESSES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createAddress(context, { waiter, payload }) {
    return resource.post(`/waiters/${waiter}/addresses`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateAddress(context, { waiter, address, payload }) {
    return resource.put(`/waiters/${waiter}/addresses/${address}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteAddress(context, { waiter, address }) {
    return resource.delete(`/waiters/${waiter}/addresses/${address}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
