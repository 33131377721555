<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Endereço</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Endereço</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="6">
                  <a-city-search v-model="payload.city_id" label="Cidade*" type="text"
                                 :error-messages="formErrors.city_id" autocomplete="disable"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.address" label="Endereço*" type="text"
                                :error-messages="formErrors.address"
                                :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="payload.cep" label="CEP*" type="number"
                                :error-messages="formErrors.cep"
                                :rules="[$rules.required, $rules.cep]"/>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="payload.number" label="Número" type="number"
                                :error-messages="formErrors.number"/>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="payload.district" label="Bairro*" type="text"
                                :error-messages="formErrors.district"
                                :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.complement" label="Complemento" type="text"
                                :error-messages="formErrors.complement"/>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="payload.reference" label="Referência" type="text"
                                :error-messages="formErrors.reference"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeAddress()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateAddress()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import ACitySearch from "@/components/ACitySearch";

  export default {
    name: 'a-address-form',
    components: {ACitySearch},
    data: () => ({
      formErrors: {},
      payload: {
        address: '',
        cep: '',
        number: '',
        district: '',
        complement: '',
        reference: '',
        city_id: ''
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchAddress', {
          waiter: this.waiter.id,
          address: this.$route.params.address
        }).then((response) => {
          this.payload = {
            address: response.data.data.address,
            cep: response.data.data.cep,
            number: response.data.data.number,
            district: response.data.data.district,
            complement: response.data.data.complement,
            reference: response.data.data.reference,
            city_id: response.data.data.city ? response.data.data.city.id : ''
          }
        });
      }
    },
    methods: {
      storeAddress() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createAddress', {
          waiter: this.waiter.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateAddress() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateAddress', {
          waiter: this.waiter.id,
          address: this.$route.params.address,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'addresses_create';
      },
      updating() {
       return this.$route.name === 'addresses_update';
      },
      waiter() {
        return this.$store.getters.getLoginAuthWaiter;
      },
    },
  };
</script>
